import React, { useContext, useEffect } from "react"
import Layout from "../modules/Layout"
import { graphql } from "gatsby"
import Main from "gatsby-singita-theme/src/components/Main"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import { useSeo } from "gatsby-singita-theme/src/hooks"
import { ResourceSetContext } from "gatsby-singita-theme/src/context/ResourceSetContext"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"

const DATA_MAP = {
  title: ["name"],
  image: ({ layout }) => 
    {
      if (layout && layout[0].__typename ==="ContentfulLayoutHero")
      {
        if (layout[0] && layout[0]?.images)
          return layout[0]?.images[0]?.fluid?.src 
        if (layout[0] && layout[0]?.imageRef)
          return layout[0]?.imageRef?.image?.fluid?.src
      }
     return null;
    } 
}

const PageTemplate = props => {
  const {
    data: { contentfulPages },
    pageContext: { slug },
  } = props

  const { getResourcesForComponent } = useContext(ResourceSetContext)
  const globalRS = getResourcesForComponent("global")
  const socialRS = getResourcesForComponent("social")
  const homeJsonLd = getJsonLd(
    { ...globalRS, ...socialRS },
    TEMPLATE_TYPES.HOME
  )
  
  const seoProps = useSeo(contentfulPages, DATA_MAP)
  const pageJsonLd = getJsonLd({ ...seoProps }, TEMPLATE_TYPES.PAGE)

  const { setLodgeData } = useContext(LodgeContext)

  const {
    privateVillas: { color },
  } = getResourcesForComponent("lodges")

  useEffect(() => {
    if (slug === "private-villas") {
      setLodgeData(color)
      return () => setLodgeData(null, null)
    }
  }, [slug, color, setLodgeData])

  const codes =
    contentfulPages.connectedEntry && contentfulPages.connectedEntry.code
      ? { region: contentfulPages.connectedEntry.code }
      : {}

  return (
    <Main {...props} codes={codes}>
      <HelmetWrapper
        {...seoProps}
        schemaJsonLd={slug === "home" ? homeJsonLd : pageJsonLd}
      />
      <Layout layout={contentfulPages.layout} slug={slug} codes={codes} />
    </Main>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!, $locale: String) {
    contentfulPages(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      slug
      name
      structuredData {
        headline
        menuLabel
        description {
          description
        }
        image {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        altText
        noIndex
        noFollow
      }
      connectedEntry {
        ... on ContentfulRegion {
          code
        }
      }
      layout {
        __typename
        ... on Node {
          ... on ContentfulContent {
            ...Content
          }
          ... on ContentfulLayoutGallery {
            ...GalleryFields
          }
          ... on ContentfulLayoutSection {
            ...SectionFields
          }
          ... on ContentfulLayoutProjectsGrid {
            ...ProjectsGridFields
          }
          ... on ContentfulLayoutHero {
            ...HeroFields
          }
          ... on ContentfulLayoutSectionLeftRight {
            ...SectionLeftRightFields
          }
          ... on ContentfulLayoutSectionGrid {
            ...SectionGridFields
          }
          ... on ContentfulLayoutSectionGridLodges {
            ...SectionGridLodgesFields
          }
          ... on ContentfulLayoutSectionGridLodgesPrivateVillas {
            ...SectionGridLodgesPrivateVillasFields
          }
          ... on ContentfulLayoutSectionGridLodgesRates {
            ...SectionGridLayoutLodgesRatesFields
          }
          ... on ContentfulLayoutCardHero {
            ...CardHeroFields
          }
          ... on ContentfulLayoutArchive {
            ...ArchiveFields
          }
          ... on ContentfulLayoutBanner {
            ...BannerFields
          }
          ... on ContentfulLayoutList {
            ...ListFields
          }
          ... on ContentfulAlert {
            ...AlertFields
          }
          ... on ContentfulActionbar {
            ...ActionBarFields
          }
          ... on ContentfulLayoutHeroVideo {
            ...HeroVideoFields
          }
          ... on ContentfulLayoutFeaturedArticles {
            ...FeaturedArticlesFields
          }
          ... on ContentfulLayoutBorder {
            ...BorderFields
          }
          ... on ContentfulLayoutContent {
            ...LayoutContentFields
          }
          ... on ContentfulActionbarBookingForm {
            ...ActionbarBookingFields
          }
        }
      }
    }
  }
`
